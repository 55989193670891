import React, { useState, useEffect } from 'react'
import './hamburger-styles.scss'

const HamburgerMenu = ({ toggle, isOpen }) => {
  const [style, setStyle] = useState(false)

  const changeStyle = () => {
    toggle()
  }

  useEffect(() => {
    setStyle(!style)
  }, [isOpen])

  return (
    <div className="hamburger" onClick={changeStyle} onKeyDown={changeStyle}>
      <div className={style ? 'hamburger__line' : 'hamburger__line1'}></div>
      <div className={style ? 'hamburger__line' : 'hamburger__line2'}></div>
      <div className={style ? 'hamburger__line' : 'hamburger__line3'}></div>
    </div>
  )
}

export default HamburgerMenu
