import React, { useState, useEffect } from 'react'
import { Link, navigate } from '@reach/router'
import i18next from 'i18next'
import { withTrans } from '../../i18n/withTrans'
import './language-selector-styles.scss'

const LanguageSelector = ({ i18n }) => {

    const handleSwitch = (e) => {
        const value = e.target.value;
        i18n.changeLanguage(value);

        navigate(value === "en" ? "/" : `/${value}/`, { replace: true });
    }
    
    return (
        <select className='language-selector'
          value={i18next.language}
          onChange={handleSwitch}
        >
          <option className='language-selector__option' value="en">EN</option>
          <option className='language-selector__option' value="zh-hant">中</option>
        </select>
      )
}

export default withTrans(LanguageSelector)
