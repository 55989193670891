import React from 'react'
import Number1 from '../../assets/icons/seasongroup_general_01.svg'
import Number2 from '../../assets/icons/seasongroup_general_02.svg'
import Number3 from '../../assets/icons/seasongroup_general_03.svg'
import Number4 from '../../assets/icons/seasongroup_general_04.svg'
import Number5 from '../../assets/icons/seasongroup_general_05.svg'
import Number6 from '../../assets/icons/seasongroup_general_06.svg'
import Number7 from '../../assets/icons/seasongroup_general_07.svg'
import Number8 from '../../assets/icons/seasongroup_general_08.svg'
import Logo from '../../assets/icons/seasongroup_main-logo.svg'
import SearchIcon from '../../assets/icons/seasongroup_icons_search.svg'
import LinkedIn from '../../assets/icons/seasongroup_icons_socmed_linkedin.svg'
import Facebook from '../../assets/icons/seasongroup_icons_socmed_facebook.svg'
import Youtube from '../../assets/icons/seasongroup_icons_socmed_youtube.svg'

const Icon = (props) => {
  const getIcon = (name) => {
    const icons = {
      icon1: Number1,
      icon2: Number2,
      icon3: Number3,
      icon4: Number4,
      icon5: Number5,
      icon6: Number6,
      icon7: Number7,
      icon8: Number8,
      logo: Logo,
      search: SearchIcon,
      linkedin: LinkedIn,
      facebook: Facebook,
      youtube: Youtube,
    }
    return icons[name]
  }
  const IconComponent = getIcon(props.name)
  return (
    <IconComponent {...props}/>
  )
}

export default Icon
