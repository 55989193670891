/* eslint-disable no-empty */
import _ from "lodash"
import React, { createContext, useState, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { makeRequest } from "../helpers"

const OpeningContext = createContext("")

const OpeningProvider = ({ children }) => {
  const { i18n } = useTranslation()

  const [openings, setOpening] = useState([""])
  const [locations, setLocations] = useState([])
  const [totalPosts, setTotalPosts] = useState(0)
  const [pageSelect, setPageSelect] = useState(1)
  const [filters, setFilters] = useState({
    per_page: 8,
    location_slug: null,
    slug: null,
    page: 1,
  })

  const fields = "id,title,slug,human_date,location,acf,excerpt"
  const fieldsLocation = "id,name,slug,count,description,taxonomy"

  const [isLoading, setIsLoading] = useState(true)

  const getOpenings = async () => {
    if (filters.page === 1) {
      setOpening([]) // If it's the first page, clear out the previous openings.
    }

    const headers = {
      "Content-Type": "application/json",
    }
    makeRequest({
      headers,
      endPoint: "careers",
      params: {
        _fields: fields,
        per_page: 8,
        location_slug: filters.location_slug,
        slug: filters.slug,
        page: filters.page,
        lang: i18n.language
      },
    })
      .then(resp => {
        setOpening(_.compact(openings.concat(resp.data)))
        setTotalPosts(Number(resp.total_items))
        setIsLoading(false) // Finish loading after fetching completes
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false) // Ensure loading finishes even if an error occurs
      })
  }
  const getLocations = async () => {
    const headers = {
      "Content-Type": "application/json",
    }
    makeRequest({
      headers,
      endPoint: "location",
      params: {
        _fields: fieldsLocation,
        lang: i18n.language
      },
    })
      .then(resp => {
        setLocations(resp.data)
      })
      .catch(error => {
        console.error(error)
      })
  }
  const onHandelLocations = e => {
    setIsLoading(true)
    setOpening([])
    let updatedValue = {
      location_slug: !_.isEmpty(e.value) ? _.toLower(e.value) : null,
      page: 1,
    }
    setFilters(oldState => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  const onHandleNextPage = value => {
    setPageSelect(value)
    let updatedValue = {}
    updatedValue = {
      page: value,
    }
    if (typeof window !== "undefined") {
      // window.scrollTo(0, 0)
    }
    setFilters(oldState => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  useEffect(() => {
    getOpenings()
    getLocations()
  }, [])

  useEffect(() => {
    getOpenings()
  }, [filters])

  const values = {
    openings,
    locations,
    totalPosts,
    pageSelect,
    onHandelLocations,
    onHandleNextPage,
    isLoading,
  }

  return (
    <OpeningContext.Provider value={values}>{children}</OpeningContext.Provider>
  )
}

const useOpening = () => {
  const context = useContext(OpeningContext)

  if (!context) {
    throw new Error("useLocale must be used within an OpeningProvider")
  }

  return context
}

export { OpeningProvider, useOpening }
