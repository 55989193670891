import _ from 'lodash'
import React, { useState } from 'react'
import { Link } from 'gatsby'
import { withTrans } from '../../i18n/withTrans'
import './footer-styles.scss'
import { useSettings } from '../../hooks/use-settings'
import Logo from '../../assets/icons/seasongroup_main-logo.svg'
import Icon from '../icon/index.jsx'
import IconArrowDown from '../../assets/icons/seasongroup_icons_chevron-down.svg'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'

const Footer = ({ t }) => {
  const { settings, menu, menuFooter } = useSettings()
  const [whichOpen, setWhichOpen] = useState(null)
  const location = useLocation();

  const { i18n } = useTranslation();

  const onHandleOpenFooterMenu = (idItem) => {
    setWhichOpen((prevState) => {
      if (prevState === idItem) {
        return null
      }
      return idItem
    })
  }

  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__menu__logos">
          <Link to={`${i18n.language === 'en' ? '/' : `/${i18n.language}/`}`}>
            <div style={{ maxWidth: '172px' }}>
                <Logo />
            </div>
          </Link>
          <p>{_.get(settings, 'who_we_are') || t('footer-menu.desc')}</p>
          <div className="footer__email">
            <a href="mailto:inquiry@seasongroup.com">inquiry@seasongroup.com</a>
          </div>
          <div className="footer__socialIconsContainer">
            {_.map(_.get(settings, 'social'), (item, idx) => (
              <a
                key={idx}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-circle">
                  <Icon className="icons-linkedin" name={item.network.value} />
                </div>
              </a>
            ))}
          </div>
        </div>
        <div className="footer__menu__list">
          <div className="footer__menu">
            {' '}
            {/* #TODO create a dropwdown of the list in mobile view */}
            <div>
              <div
                className={
                  whichOpen === 'services'
                    ? 'footer__item open'
                    : 'footer__item'
                }
              >
                <span>{_.get(menuFooter, '[0].nav_blocks[0].link.text') || t('footer-menu.services')}</span>
                <IconArrowDown
                  onClick={() => onHandleOpenFooterMenu('services')}
                  width="15"
                />
              </div>
              <div
                className={
                  whichOpen === 'services'
                    ? 'footer__listItems open'
                    : 'footer__listItems'
                }
              >
                {_.map(_.get(menuFooter, '[0].nav_blocks[0].sub_nav'), (item) => (
                  <Link key={item.link.url} to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/services/${item.link.url}/`}>
                    {item.link.text || t(`nav.services.sub_nav.${item.link.url}`)}
                  </Link>
                ))}
              </div>
            </div>
            <div>
              <div
                className={
                  whichOpen === 'industries'
                    ? 'footer__item open'
                    : 'footer__item'
                }
              >
                <span>{_.get(menuFooter, '[1].nav_blocks[0].link.text') || t('footer-menu.industries')}</span>
                <IconArrowDown
                  onClick={() => onHandleOpenFooterMenu('industries')}
                  width="15"
                />
              </div>
              <div
                className={
                  whichOpen === 'industries'
                    ? 'footer__listItems open'
                    : 'footer__listItems'
                }
              >
                {/* {console.log('jhgdfjhdsbfdsdgdsfddsfds ', menu)} */}
                {/* {console.log('jhgdfjhdsbfdsdgdsfd ', menuFooter)} */}
                {/* {console.log('jhgdfjhdsbfds ', _.get(menuFooter, '[1].nav_blocks[0].sub_nav'))} */}
                {_.map(_.get(menuFooter, '[1].nav_blocks[0].sub_nav'), (item, idx) => (
                  <Link
                    key={item.link.url}
                    to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/industries/${item.link.url}/`}
                  >
                    {item.link.text || t(`nav.industries.sub_nav.${item.link.url}`)}
                  </Link>
                ))}
              </div>
            </div>
            <div>
              <div
                className={
                  whichOpen === 'about-us'
                    ? 'footer__item open'
                    : 'footer__item'
                }
              >
                <span>{_.get(menuFooter, '[2].nav_blocks[0].link.text') || t('footer-menu.about-us')}</span>
                <IconArrowDown
                  onClick={() => onHandleOpenFooterMenu('about-us')}
                  width="15"
                />
              </div>
              <div
                className={
                  whichOpen === 'about-us'
                    ? 'footer__listItems open'
                    : 'footer__listItems'
                }
              >
                <Link to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/about-us/`}>{menuFooter[2]?.nav_blocks[0]?.sub_nav[0].link.text}</Link>
                <Link to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/about-us/#our-locations`}>
                  {menuFooter[2]?.nav_blocks[0]?.sub_nav[1]?.link.text}
                </Link>
                <Link to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/leadership/`}>{menuFooter[2]?.nav_blocks[0]?.sub_nav[2]?.link.text}</Link>
                <Link to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/csr/`}>{menuFooter[2]?.nav_blocks[0]?.sub_nav[3]?.link.text}</Link>
                <a
                  href="https://www.sgwireless.com/ "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {menuFooter[2]?.nav_blocks[0]?.sub_nav[4]?.link.text}
                </a>
              </div>
            </div>
            <div>
              <div
                className={
                  whichOpen === 'group' ? 'footer__item open' : 'footer__item'
                }
              >
                <span>{_.get(menuFooter, '[3].nav_blocks[0].link.text') || t('footer-menu.season-group')}</span>
                <IconArrowDown
                  onClick={() => onHandleOpenFooterMenu('group')}
                  width="15"
                />
              </div>
              <div
                className={
                  whichOpen === 'group'
                    ? 'footer__listItems open'
                    : 'footer__listItems'
                }
              >
                {/* {console.log('menu 97987', menuFooter)} */}
                <Link to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/news/`}>{menuFooter[3]?.nav_blocks[0]?.sub_nav[0].link.text}</Link>
                <Link to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/insights/`}>{menuFooter[3]?.nav_blocks[0]?.sub_nav[1].link.text}</Link>
                <Link to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/work-with-us/`}>{menuFooter[3]?.nav_blocks[0]?.sub_nav[2].link.text}</Link>
                <Link to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/careers/`}>{menuFooter[3]?.nav_blocks[0]?.sub_nav[3].link.text}</Link>
                <Link to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/supplier-resources/`}>
                  {menuFooter[3]?.nav_blocks[0]?.sub_nav[4].link.text}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyRight">
        <div className="footer__copyRight__box">
          <p>{_.get(settings, 'copyright')}</p>
          <p>
            {_.map(_.get(settings, 'service_links'), (item, idx) => (
              <span className="footer__copyRight__item" key={idx}>
                <Link to={`${!location.pathname.includes('zh-hant') ? '' : `/zh-hant`}/${item.link.url}`}>{item.link.text}</Link>
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  )
}

export default withTrans(Footer)
