import _ from 'lodash'

const getOptions = (options, initialValue) => _.reduce(options, (result, value, key) => {
  result.push({
    value: value.slug,
    label: value.name,

  })
  return result
}, [{
  value: null,
  label: initialValue,
}])

export default getOptions
