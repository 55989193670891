import React, { useState } from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'
import classnames from 'classnames'
import IconDownArrow from '../../../../assets/icons/seasongroup_icons_chevron-down.svg'

const SubListItem = ({
  item,
  subNav,
  toggleMenu,
}) => {
  if (item.link.url === 'about-us') {
    return <li key={subNav.link.url} className="list-link__subnav" onClick={toggleMenu}>
      <Link to={`/${subNav.link.url}/`} >
        <span>{subNav.link.text}</span>
      </Link>
    </li>
  }
  return <li key={subNav.link.url} className="list-link__subnav" onClick={toggleMenu}>
    <Link to={`/${item.link.url}/${subNav.link.url}/`} >
      <span>{subNav.link.text}</span>
    </Link>
  </li>
}

const ItemMenuMobile = ({
  item,
  pathname,
  toggleMenu,
}) => {
  const [isOpenSubnav, setIsOpenSubnav] = useState(false)
  const classSubnav = classnames({
    list__subNav: true,
    list__subNav__open: isOpenSubnav,
  })
  const classArrow = classnames({
    list__arrow: true,
    list__arrow__open: isOpenSubnav,
  })
  const classList = classnames({
    header__list: true,
    isactive: _.includes(pathname, item.link.url),
  })
  const link = _.isEmpty(item.sub_nav) ? `/${item.link.url}/` : null
  const onHandelSubMenu = () => {
    setIsOpenSubnav(!isOpenSubnav)
  }
  return (
    <li
      className={classList}
      key={item.link.text}
    >
      {
        link ? (
          <Link to={link} className="list-link" onClick={toggleMenu} >
            <span>{item.link.text}</span>
          </Link>

        ) : (
          <p
            onClick={() => onHandelSubMenu(item)}
            className="list-text list-text-mobile">
            <span>{item.link.text}</span>
            <span className={classArrow}>
              <IconDownArrow width="14" />
            </span>
          </p>
        )
      }
      <ul className={classSubnav}>
        {
          item.sub_nav && _.map(item.sub_nav, (subNav, idx) => (
            <SubListItem
              subNav={subNav}
              item={item}
              toggleMenu={toggleMenu}
              key={idx}
            />
          ))
        }

      </ul>
    </li>
  )
}

export default ItemMenuMobile
