import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import ItemMenuDesktop from '../item-menu-desktop'

const ListMenu = ({
  breakpoints,
  toggleSubMenu,
  open,
  pathname,
  menu,
}) => {
  if (_.isEmpty(menu)) return null

  return (
    _.map(menu, (item, idx) => (
      <React.Fragment key={item.link.text}>
        <ItemMenuDesktop
          item={item}
          idx={idx}
          breakpoints={ breakpoints}
          toggleSubMenu={toggleSubMenu}
          open={open}
          pathname={pathname}
        />
      </React.Fragment>
    ))
  )
}

export default ListMenu
