/* eslint-disable no-empty */
import _ from 'lodash'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../helpers'

const SettingsContext = createContext('')

const SettingsProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [menu, setMenu] = useState([])
  const [menuFooter, setMenuFooter] = useState([])
  const [settings, setSettings] = useState([])

  const getGlobalSettings = async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'global/',
      params: {
        lang: i18n.language
      },
    }).then((resp) => {
      setSettings(resp.data)

      const newMenu = _.map(resp.data.nav, (nav) => {
        if (nav.link.url === 'about-us') {
          return {
            ...nav,
            sub_nav: [
              {
                link: {
                  text: 'Our story',
                  url: 'about-us',
                },
              }, {
                link: {
                  text: 'Leadership',
                  url: 'leadership',
                },
              }, {
                link: {
                  text: 'CSR',
                  url: 'csr',
                },
              },
            ],
          }
        }
        return nav
      })
      setMenu(newMenu)

      const newMenu1 = _.map(resp.data.secondary_nav, (secondary_nav) => secondary_nav)
      setMenuFooter(newMenu1)
    })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getGlobalSettings()
  }, [i18n.language])

  useEffect(() => {
    getGlobalSettings()
  }, [])

  const values = {
    settings,
    menu,
    menuFooter,
  }

  return (
    <SettingsContext.Provider value={values}>
      {children}
    </SettingsContext.Provider>
  )
}

const useSettings = () => {
  const context = useContext(SettingsContext)

  if (!context) { throw new Error('useLocale must be used within an SettingsProvider') }

  return context
}

export { SettingsProvider, useSettings }
